import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import { Link } from "@remix-run/react";

import { UpdateDot } from "~/components/site/update-dot";

type SubNavItem = {
  name: string;
  href: string;
  external?: boolean;
};

export type NavItem = Readonly<{
  name: string;
  href: string;
  external?: boolean;
  dotFeature?: string;
  feature?: string;
  subItems?: SubNavItem[];
}>;

type Props = Readonly<{
  className: string;
  item: NavItem;
}>;

export function Nav({ className, item }: Props) {
  return item.feature ? (
    <IfFeatureEnabled feature={item.feature}>
      <Item className={className} item={item} />
    </IfFeatureEnabled>
  ) : (
    <Item className={className} item={item} />
  );
}

function Item({ className, item }: Props) {
  return (
    <Link className={className} prefetch="intent" to={item.href}>
      {item.dotFeature ? (
        <div className="flex flex-row justify-center items-center gap-x-1 sm:gap-x-2">
          <IfFeatureEnabled feature={item.dotFeature}>
            <UpdateDot />
          </IfFeatureEnabled>{" "}
          {item.name}
        </div>
      ) : (
        item.name
      )}
    </Link>
  );
}
