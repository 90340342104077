import { useFetcher } from "@remix-run/react";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import { thrw } from "~/lib/thrw";

export enum Theme {
  Dark = "dark",
  Light = "light",
}
const themes: Theme[] = Object.values(Theme);

type Props = Readonly<{
  children: ReactNode;
  specifiedTheme: Theme | null;
}>;

export type ThemeContextType = [
  Theme | null,
  Dispatch<SetStateAction<Theme | null>>
];

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const prefersLightMQ = "(prefers-color-scheme: light)";
const getPreferredTheme = () =>
  window.matchMedia(prefersLightMQ).matches ? Theme.Light : Theme.Dark;

export function ThemeProvider({
  children,
  specifiedTheme,
}: Props): JSX.Element {
  const [theme, setTheme] = useState<Theme | null>(() => {
    // If no specified theme on server, return null. clientThemeCode will set it
    // before hydration. During hydration, this code will get the same
    // value so hydration is happy.
    if (specifiedTheme) {
      return themes.includes(specifiedTheme) ? specifiedTheme : null;
    }
    if (typeof window !== "object") return null;

    return getPreferredTheme();
  });

  const persistTheme = useFetcher();
  const mountRun = useRef(false);

  useEffect(() => {
    if (mountRun.current == null) {
      mountRun.current = true;
      return;
    }
    if (theme == null) return;

    persistTheme.submit(
      { theme },
      { action: "action/set-theme", method: "post" }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    const mediaQuery = window.matchMedia(prefersLightMQ);
    const handleChange = () =>
      setTheme(mediaQuery.matches ? Theme.Light : Theme.Dark);
    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </ThemeContext.Provider>
  );
}

const clientThemeCode = `
// Avoid flashing wrong theme
;(() => {
  const theme = window.matchMedia(${JSON.stringify(prefersLightMQ)}).matches
    ? 'light'
    : 'dark';

  const cl = document.documentElement.classList;
  const themeAlreadyApplied = cl.contains('light') || cl.contains('dark');
  if (themeAlreadyApplied == null) cl.add(theme);

  const meta = document.querySelector('meta[name=color-scheme]');
  if (meta) {
    if (theme === 'dark') meta.content = 'dark light';
    else if (theme === 'light') meta.content = 'light dark';
  }
})();
`;

export function NonFlashOfWrongThemeEls({
  ssrTheme,
}: Readonly<{ ssrTheme: boolean }>): JSX.Element {
  const [theme] = useTheme();

  return (
    <>
      {/* Theme may be null on server; clientThemeCode ensures it's correct before hydration. */}
      <meta
        name="color-scheme"
        content={theme === "light" ? "light dark" : "dark light"}
      />
      {ssrTheme ? null : (
        <script
          // Cannot use type="module" because it "defer"s. It must run before
          // rest of document is finished loading.
          dangerouslySetInnerHTML={{ __html: clientThemeCode }}
        />
      )}
    </>
  );
}

export function useTheme(): ThemeContextType {
  const context = useContext(ThemeContext);
  return context == null
    ? thrw("useTheme must be used within a ThemeProvider")
    : context;
}

export function isTheme(value: unknown): value is Theme {
  return typeof value === "string" && themes.includes(value as Theme);
}
