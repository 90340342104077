import {
  faArrowUpRight,
  faBars,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Link } from "@remix-run/react";
import { Fragment } from "react";

import { Brale } from "~/components/logos/brale";
import { ExternalLink } from "~/components/site/external-link";
import type { NavItem } from "~/components/site/nav";
import { ThemeToggle } from "~/components/site/theme-toggle";
import type { ThemeContextType } from "~/lib/theme-provider";
import { useTheme } from "~/lib/theme-provider";

const nav: NavItem[] = [
  { name: "Product", href: "/product" },
  { name: "Pricing", href: "/pricing" },
  {
    name: "Resources",
    href: "/",
    subItems: [
      { name: "Case Studies", href: "/case-studies" },
      { name: "Ecosystem", href: "/ecosystem" },
      { name: "Coin View", href: "/stablecoins" },
      { name: "Blog", href: "/blog" },
      { name: "Docs", href: "https://docs.brale.xyz/", external: true },
    ],
  },
  { name: "About", href: "/about" },
];

export function Header(): JSX.Element {
  return <HeaderImpl ctx={useTheme()} />;
}

export function UnThemedHeader(): JSX.Element {
  return <HeaderImpl />;
}

function HeaderImpl({
  ctx,
}: Readonly<{ ctx?: ThemeContextType }>): JSX.Element {
  return (
    <Popover as="div" className="text-sm z-[200]">
      <div className="container">
        <div className="flex py-[15px] md:py-[17px]">
          <div className="flex items-center mr-8">
            <Link prefetch="intent" aria-label="Brale" to="/">
              <Brale />
            </Link>
          </div>
          <div className="hidden md:flex md:flex-1 col-span-2 md:items-center text-gray-500 dark:text-gray-400 print:hidden">
            <PopoverGroup
              as="nav"
              className="flex space-x-2 w-full z-10 items-center"
            >
              {nav.map((n) =>
                n.subItems ? (
                  <div className="relative inline-block" key={n.name}>
                    <div className="group">
                      <button
                        type="button"
                        className="btn nav global-hover-transition"
                      >
                        Resources
                        <FontAwesomeIcon
                          className="h-4 w-4 ml-2 global-hover-transition"
                          icon={faChevronDown}
                          aria-hidden="true"
                        />
                      </button>
                      <div className="absolute left-4 top-full mt-1 round bg-secondary border border-primary p-1 text-sm font-medium opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:translate-y-[6px] transition-all duration-300">
                        <div className="py-1" role="none">
                          {n.subItems.map((item) =>
                            item.external ? (
                              <ExternalLink
                                key={item.name}
                                className="btn nav gap-x-2 global-hover-transition"
                                href={item.href}
                                title={`View ${item.name}`}
                              >
                                {item.name}
                                <FontAwesomeIcon
                                  className="h-4 w-4 mt-0.5"
                                  icon={faArrowUpRight}
                                />
                              </ExternalLink>
                            ) : (
                              <Link
                                key={item.name}
                                className="btn nav global-hover-transition"
                                aria-label={item.name}
                                to={item.href}
                              >
                                {item.name}
                              </Link>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link
                    key={n.name}
                    className="btn nav global-hover-transition"
                    prefetch="intent"
                    aria-label={n.name}
                    to={n.href}
                  >
                    {n.name}
                  </Link>
                )
              )}
            </PopoverGroup>
          </div>
          <div className="flex col-span-1 items-center ml-auto gap-x-4 justify-self-stretch print:hidden">
            <ThemeToggle ctx={ctx} />
            <Link
              className="btn nav !hidden md:!inline-block global-hover-transition"
              aria-label="Log in"
              to="https://app.brale.xyz/login"
            >
              Log in
            </Link>
            <Link
              className="btn primary md:ml-4 !ml-0"
              prefetch="intent"
              aria-label="Talk to sales"
              to="/contact"
            >
              Talk to sales
            </Link>
            <PopoverButton className="flex items-center focus:ring-0 focus:outline-none md:!hidden">
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon
                aria-hidden="true"
                className="header-icon"
                icon={faBars}
              />
            </PopoverButton>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <PopoverPanel className="z-30 transition md:hidden text-sm">
          <div className="pt-4 border-t border-b border-primary">
            <div className="grid xs:grid-cols-2 px-4 gap-3 mb-4">
              {nav.map((n) =>
                n.subItems ? (
                  n.subItems.map((item) =>
                    item.external ? (
                      <ExternalLink
                        key={item.name}
                        className="btn nav gap-x-2"
                        href={item.href}
                        title={`View ${item.name}`}
                      >
                        {item.name}
                        <FontAwesomeIcon
                          className="h-4 w-4 mt-0.5"
                          icon={faArrowUpRight}
                        />
                      </ExternalLink>
                    ) : (
                      <Link
                        key={item.name}
                        className="btn nav"
                        aria-label={item.name}
                        to={item.href}
                      >
                        {item.name}
                      </Link>
                    )
                  )
                ) : (
                  <Link
                    key={n.name}
                    className="btn nav"
                    prefetch="intent"
                    aria-label={n.name}
                    to={n.href}
                  >
                    {n.name}
                  </Link>
                )
              )}
            </div>
            <div className="flex flex-col xs:flex-row border-t border-primary items-center p-4 gap-3">
              <Link
                className="btn primary nav-mobile md:ml-4 w-full"
                aria-label="Schedule Demo"
                to="/contact"
              >
                Talk to sales
              </Link>
              <a
                className="btn nav-mobile w-full secondary"
                href="https://app.brale.xyz/login"
              >
                Log in
              </a>
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
