import type { SVGProps } from "react";

interface BraleProps extends SVGProps<SVGSVGElement> {
  height?: string;
  width?: string;
}

export function Brale({
  height = "36px",
  width = "36px",
  ...props
}: BraleProps): JSX.Element {
  return (
    <svg
      className="fill-black dark:fill-white"
      height={height}
      width={width}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.4344 11.5798C6.31341 9.18809 6.31341 5.31042 10.4344 2.91875C14.5553 0.527082 21.2368 0.527082 25.3577 2.91875C29.4787 5.31042 29.4787 9.18809 25.3577 11.5798C21.2368 13.9714 14.5553 13.9714 10.4344 11.5798Z"
        fill="fill-black"
      />
      <path
        d="M21.0754 29.8802C21.0754 25.1153 24.4162 19.2799 28.5373 16.8882C32.6584 14.4964 35.9993 16.454 35.9993 21.2189C35.9993 25.9837 32.6584 31.8191 28.5373 34.2108C24.4162 36.6026 21.0754 34.6451 21.0754 29.8802Z"
        fill="fill-black"
      />
      <path
        d="M7.46195 16.8879C3.34083 14.4961 0 16.4536 0 21.2185C0 25.9834 3.34083 31.8188 7.46195 34.2105C11.5831 36.6023 14.9239 34.6447 14.9239 29.8799C14.9239 25.115 11.5831 19.2796 7.46195 16.8879Z"
        fill="fill-black"
      />
    </svg>
  );
}
