import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import { Link } from "@remix-run/react";

export function Announcement(): JSX.Element {
  return (
    <IfFeatureEnabled feature="site-announcement">
      <div className="text-white md:flex md:justify-center md:items-center md:gap-x-6 bg-purple-600 text-sm p-4 ">
        <p className="mb-2 md:mb-0">
          <span className="font-medium">Introducing Treasury</span>: Create
          fiat-backed stablecoins in an hour.
        </p>
        <Link
          prefetch="intent"
          to="/blog/introducing-treasury"
          className="font-medium whitespace-nowrap"
        >
          <div className="flex items-center md:justify-center gap-x-2">
            <span>Learn more</span>
            <FontAwesomeIcon icon={faArrowRight} className="h-[14px]" />
          </div>
        </Link>
      </div>
    </IfFeatureEnabled>
  );
}
